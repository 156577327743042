<template>
  <div class="row py-2 border-top border-skyblue">
    <div class="col-sm-5">
      <div class="row mt-1 mb-3">
        <div class="col-md-12">
          <AddServiceModal />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 pr-0">
          <label>Glosa:</label>
        </div>
        <div class="col-sm-4 pl-0">
          <input id="checkGlosa"
           v-model="glosaTipo"
           class="form-check-input"
           type="checkbox">
          <label class="form-check-label"
           for="checkGlosa">
            Privado
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <textarea
           v-model="glosa"
           class="form-control"
           placeholder="Ingrese alguna descripcion de la venta"
           rows="3"></textarea>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row mb-4"></div>
      <div class="row mb-2">
        <div class="col-sm-3 px-0">
        </div>
        <div class="col-sm-4">
          <FormaPagoModal />
        </div>
        <div class="col-sm-3" v-if="islistaTablaVacia === false">
          <b-badge pill variant="secondary p-2 mx-1 mb-1"
           v-for="(item, index) in getListaBadges" :key="index"
           >
            {{item.nombre}}
          </b-badge>
        </div>
      </div>
    </div>
    <div class="col-sm-3 pl-0">
      <div class="row mb-2" v-if="isDisableSeguro">
        <div class="col-sm-6 text-right">
          <label class="col-form-label">
            <b>Sub Total:</b>
          </label>
        </div>
        <div class="col-sm-6 pl-0">
          <div class="div-input">{{getSubTotal | numeral('0,0.00')}}</div>
        </div>
      </div>
      <div class="row" v-if="isDisableSeguro">
        <div class="col-sm-6 text-right">
          <label class="col-form-label">
            <b>- Seguro:</b>
          </label>
        </div>
        <div class="col-sm-6 pl-0">
          <div class="div-input">{{totalSeguro | numeral('0,0.00')}}</div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm-6 text-right">
          <label class="col-form-label">
            <b>Desc:</b>
          </label>
        </div>
        <div class="col-sm-6 pl-0">
        <input type="text" class="form-control" disabled>
        </div>
      </div> -->
      <div
        class="row mb-2 th-custom-color py-2"
        :class="hasSeguroDoble && this.has_active_doble_seguro ? 'custom-row-total': ''"
      >
        <div
          v-if="hasSeguroDoble && this.has_active_doble_seguro"
          class="col-sm-4 pl-2 pr-0 pb-2"
        >
        <!-- :disabled="getModoEdicion" -->
          <multiselect
            style="width: 162px;"
            v-model="seguroDobleSelected"
            :options="getFilteredAseguradora"
            placeholder="Seguro Doble"
            label="nombre"
            track-by="id"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(seguroDobleSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
          </multiselect>
        </div>
        <div
          class="text-right"
          :class="hasSeguroDoble && this.has_active_doble_seguro ? 'col-sm-3 custom-pr-total':'col-sm-6'"
        >
          <h4 class="pt-1 text-dark">
            <b>Total:</b>
          </h4>
        </div>
        <div
          :class="hasSeguroDoble && this.has_active_doble_seguro ? 'col-sm-5 pl-1':'col-sm-6 pl-0'"
        >
          <div class="div-input bg-main">{{(getTotal) | numeral('0,0.00')}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 offset-sm-6 pl-0">
          <button class="btn btn-warning col-sm-12"
            @click="guardarFormulario"
            :disabled="buttonDisabled">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import Multiselect from 'vue-multiselect';
import privilegios from '@/plugins/privilegios/index';
import { util } from '@/plugins/util';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import AddServiceModal from './AddServiceModal.vue';
import FormaPagoModal from './FormaPagoModal.vue';

export default {
  name: 'DetalleFactura',
  components: {
    FormaPagoModal,
    Multiselect,
    AddServiceModal,
  },
  data() {
    return {
      buttonDisabled: false,
      isDisableSeguro: false,
      has_active_doble_seguro: false,
    };
  },
  created() {
    const funcionalidadId = this.funcionalidadAccions.SEG_LISTAR.funcionalidad_id;
    const funcionalidadAccion = this.funcionalidadAccions.SEG_LISTAR.accion;
    this.isDisableSeguro = this.existePrivilegios(funcionalidadId, funcionalidadAccion);
    this.getParametrosOtros();
  },
  mixins: [privilegios],
  methods: {
    redirect(path) {
      this.$router.push(path);
    },
    async store() {
      if (this.getPacienteSelected === null) {
        util.showNotify('Elija un Paciente', 'warn');
        return;
      }
      if (this.checkFacturaTercero && this.isTipoFacturaManual) {
        if (this.nroFactura === null || this.nroFactura === '') {
          util.showNotify('Indique el numero de la Factura', 'warn');
          return;
        }
      }
      if (this.caja + this.banco + this.tarjeta > this.getSubTotal) {
        util.showNotify('el monto de pago es superior al subTotal', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const { STORE_OK, ventaId } = await this.callStoreApi();
      if (STORE_OK) {
        this.redirect(this.getPathForRedirect(ventaId));
      }
      this.buttonDisabled = false;
    },
    getPathForRedirect(ventaId) {
      if (this.isTipoFacturaComputarizada) {
        return { name: 'facturacion-print', params: { id: btoa(ventaId) } };
      }
      return { name: 'facturacion-index' };
    },
    async update() {
      if (this.getPacienteSelected === null) {
        util.showNotify('Elija un Paciente', 'warn');
        return;
      }
      if (this.caja + this.banco + this.tarjeta > this.getSubTotal) {
        util.showNotify('el monto de pago es superior al subTotal', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const id = atob(this.$route.params.id);
      const UPDATE_OK = await this.callUpdateApi(id);
      if (UPDATE_OK) {
        this.redirect({ name: 'facturacion-index' });
      }
      this.buttonDisabled = false;
    },
    guardarFormulario() {
      if (this.isEnabledFormaPago === false) {
        util.showNotify('Ingrese forma de pago!', 'warn');
        return;
      }
      if (this.checkSeguroEstado && parseFloat(this.totalSeguro) <= 0) {
        util.showNotify('Ingrese un servicio con seguro!', 'warn');
        return;
      }
      if (this.getModoEdicion) {
        this.update();
      } else {
        this.store();
      }
    },
    ...mapActions('facturacion', [
      'callStoreApi',
      'callUpdateApi',
    ]),
    async getParametrosOtros() {
      try {
        const response = await axios.get('/clinic/parametros/other/create');
        this.has_active_doble_seguro = response.data.data.has_active_doble_seguro === 1;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapGetters('tableAndAddService', [
      'islistaTablaVacia',
    ]),
    ...mapFields('formFactura', [
      'checkSeguroEstado',
      'nroFactura',
      'checkFacturaTercero',
      'aseguradoraSelected',
    ]),
    ...mapFields('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    ...mapFields('detalleFactura', [
      'glosa',
      'glosaTipo',
      'totalSeguro',
      'seguroDobleSelected',
    ]),
    ...mapFields('main', [
      'funcionalidadAccions',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
    ...mapGetters('formFactura', [
      'getCheckSeguroEstado',
      'getPacienteSelected',
      'isTipoFacturaManual',
      'isTipoFacturaComputarizada',
      'getComboAseguradora',
    ]),
    ...mapGetters('formaPagoModal', [
      'isEnabledFormaPago',
    ]),
    ...mapGetters('detalleFactura', [
      'getSubTotal',
      'getTotal',
      'getListaBadges',
    ]),
    getFilteredAseguradora() {
      if (this.aseguradoraSelected !== null) {
        return this.getComboAseguradora.filter((item) => (
          item.id !== this.aseguradoraSelected.id
        ));
      }
      return this.getComboAseguradora;
    },
    hasSeguroDoble() {
      return this.aseguradoraSelected != null && this.getCheckSeguroEstado;
    },
  },
};
</script>

<style scoped>
.border-skyblue {
  border-color: #61c6f2 !important;
}
.div-input {
  outline: initial !important;
  border: 1px solid #ced4da;
  color: #474d6d;
  background-color: rgb(238, 238, 238);
  opacity: 1;
  display: block;
  width: 100%;
  height: calc(1.9695rem + 2px);
  padding: .375rem .75rem;
  font-size: .813rem;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.custom-row-total {
  width: 411px;
  transform: translateX(-77px);
}
.custom-pr-total {
  padding-right: 0.67rem;
}
</style>
